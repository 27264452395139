import 'common-web/src/sass/header.scss';
import './Navigation.scss';
import { ReactComponent as LogoSvg } from 'common-web/src/assets/header-logo.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { gtag } from './utils';
import { useAuth } from './AuthProvider';

const MIN_NAVIGATION_HEIGHT = '4rem';
const DONATION_URL = 'https://buy.stripe.com/8wMeWx9x87NMcU08ww';

function Navigation({
  isMenuOpen,
  isMenuClosing,
  openMenu,
  closeMenu,
  activeGameId,
  setActiveGameId
}) {

  const navigate = useNavigate();
  const location = useLocation();
  const {
    authInfo,
    onLogout,
    clientId
  } = useAuth();
  const [headerHeight, setHeaderHeight] = useState(MIN_NAVIGATION_HEIGHT);
  const staticMenuRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const navigateTo = useCallback((to, navigateOptions) => {
    closeMenu();
    setActiveGameId(null);
    if (location.pathname === to) {
      window.location.reload();
    } else {
      if (navigateOptions) {
        navigate(to, navigateOptions);
      } else {
        navigate(to);
      }
    }
  }, [closeMenu, setActiveGameId, location, navigate]);

  const openHomePage = useCallback(() => {
    navigateTo('/');
  }, [navigateTo]);

  const onLogoClick = useCallback((e) => {
    e.preventDefault();
    gtag('event', 'logo_click', {
      'unique_client_id': clientId
    });
    openHomePage();
  }, [openHomePage, clientId]);

  const onHomeClick = useCallback((e) => {
    e.preventDefault();
    gtag('event', 'home_click', {
      'unique_client_id': clientId
    });
    openHomePage();
  }, [openHomePage, clientId]);

  const openGamesPage = useCallback((e) => {
    e.preventDefault();
    gtag('event', 'dilemmas_click', {
      'unique_client_id': clientId
    });
    let navigateOptions;
    if (activeGameId) {
      navigateOptions = { state: { playedGameId: activeGameId } };
    } else {
      navigateOptions = null;
    }
    navigateTo('/dilemmas', navigateOptions);
  }, [navigateTo, activeGameId, clientId]);

  const openContactUsPage = useCallback((e) => {
    e.preventDefault();
    gtag('event', 'contact_us_click', {
      'unique_client_id': clientId
    });
    navigateTo('/contact-us');
  }, [navigateTo, clientId]);

  const openManagePlanPage = useCallback((e) => {
    e.preventDefault();
    gtag('event', 'manage_plan_click', {
      'unique_client_id': clientId
    });
    navigateTo('/manage-plan');
  }, [navigateTo, clientId]);

  const openLoginPage = useCallback((e) => {
    e.preventDefault();
    gtag('event', 'login_click', {
      'unique_client_id': clientId
    });
    navigateTo('/login');
  }, [navigateTo, clientId]);

  const handleLogout = useCallback((e) => {
    e.preventDefault();
    gtag('event', 'logout_click', {
      'unique_client_id': clientId
    });
    onLogout();
  }, [onLogout, clientId]);

  const toggleMenu = useCallback(() => {
    gtag('event', 'toggle_menu', {
      'unique_client_id': clientId
    });
    if (isMenuOpen) {
      closeMenu();
    } else {
      openMenu();
    }
  }, [isMenuOpen, closeMenu, clientId]);

  const getMenuStateClass = useCallback(() => {
    if (isMenuClosing) {
      return '';
    }
    return isMenuOpen ? 'open' : 'close';
  }, [isMenuClosing, isMenuOpen]);

  useEffect(() => {
    let timeoutId;

    function handleResize() {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        setWindowWidth(window.innerWidth);
      }, 250);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(timeoutId);
    };
  }, [setWindowWidth]);

  useEffect(() => {
    if (isMenuOpen && !isMenuClosing && staticMenuRef.current) {
      setHeaderHeight(
        `calc(${MIN_NAVIGATION_HEIGHT} + ${staticMenuRef.current.scrollHeight}px`
      );

    } else {
      setHeaderHeight(MIN_NAVIGATION_HEIGHT);
    }
  }, [
    isMenuOpen,
    isMenuClosing,
    setHeaderHeight,
    staticMenuRef.current?.scrollHeight,
    windowWidth
  ]);

  const openDonationPage = useCallback((e) => {
    e.preventDefault();
    gtag('event', 'donation_click', {
      'unique_client_id': clientId
    });
    window.open(DONATION_URL, '_blank');
  }, [clientId]);

  const contactUsMenuItem = <a
    className={`app-link dynamic-menu-item ${location.pathname === '/contact-us' ? 'active-app-link' : ''}`}
    onClick={openContactUsPage}
    href="/contact-us"
  >
    <i className="fa-solid fa-pencil"></i> Contact Us
  </a>;

  const managePlanMenuItem = <a
    className={`app-link dynamic-menu-item ${location.pathname === '/manage-plan' && 'active-app-link'}`}
    onClick={openManagePlanPage}
  >
    <i className="fa-solid fa-clipboard-list"></i> Manage Plan
  </a>;

  const loginMenuItem = <a
    className={`app-link dynamic-menu-item ${location.pathname === '/login' && 'active-app-link'}`}
    onClick={openLoginPage}
    href="/login"
  >
    <i className="fa-solid fa-right-to-bracket"></i> Login
  </a>;

  const logoutMenuItem = <a className="app-link dynamic-menu-item" onClick={handleLogout}>
    <i className="fa-solid fa-right-from-bracket"></i> Log Out
  </a>;

  return (<header
    className={`app-header appearing-element ${getMenuStateClass()}`}
    style={{ height: headerHeight }}
  >
    <nav className="app-navigation">
      <LogoSvg className="header-logo" onClick={onLogoClick} />
      <a
        className={`app-link ${location.pathname === '/' ? 'active-app-link' : ''}`}
        onClick={onHomeClick}
        href="/"
      >
        Home
      </a>
      <a
        className={`app-link ${location.pathname === '/dilemmas' ? 'active-app-link' : ''}`}
        onClick={openGamesPage}
        href="/dilemmas"
      >
        Dilemmas
      </a>
      <a
        className="app-link"
        onClick={openDonationPage}
        href={DONATION_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        Support Us
      </a>
    </nav>
    <nav className="secondary-navigation">
      {contactUsMenuItem}
      {authInfo && managePlanMenuItem}
      {!authInfo && loginMenuItem}
      {authInfo && logoutMenuItem}
      <button
        type="button"
        className="navbar-burger"
        onClick={toggleMenu}
        aria-label="Toggle navigation"
      >
        <i className="fa-solid fa-bars"></i>
      </button>
    </nav>
    {isMenuOpen && <div className="static-menu-items" ref={staticMenuRef}>
      {contactUsMenuItem}
      {authInfo && managePlanMenuItem}
      {!authInfo && loginMenuItem}
      {authInfo && logoutMenuItem}
    </div>}
  </header>);
}

Navigation.propTypes = {
  isMenuOpen: PropTypes.bool,
  isMenuClosing: PropTypes.bool,
  openMenu: PropTypes.func,
  closeMenu: PropTypes.func,
  activeGameId: PropTypes.any,
  setActiveGameId: PropTypes.func
};

export default Navigation;
