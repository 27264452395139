import PropTypes from 'prop-types';
import { DEFAULT_GAME_ID } from './constants';
import Game from './Game';
import { useCallback, useEffect, useState } from 'react';
import StartScreen from './StartScreen';
import { gtag } from './utils';
import { useAuth } from './AuthProvider';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { RequestStatus } from './constants';

function Home({
  closeMenu,
  setActiveGameId
}) {

  const [gameStarted, setGameStarted] = useState(false);
  const [games, setGames] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState(null);
  const { clientId, authInfo, canAccessPremiumGames } = useAuth();
  const navigate = useNavigate();

  const loadGames = useCallback(() => {
    setLoadingStatus(RequestStatus.IN_PROGRESS);
    axios.get('/api/game/list')
      .then((gamesResponse) => {
        setGames(gamesResponse.data);
        setLoadingStatus(RequestStatus.SUCCESS);
      })
      .catch(error => {
        console.error(error, 'An error occurred while fetching games');
        setLoadingStatus(RequestStatus.ERROR);
      });
  }, []);

  useEffect(() => {
    loadGames();
  }, [loadGames]);

  const startGame = useCallback(() => {
    gtag('event', 'start_game_click', {
      'game_id': 'default_game',
      'unique_client_id': clientId
    });

    const defaultGame = games.find(game => game.id === DEFAULT_GAME_ID);
    if (defaultGame && !defaultGame.isFree && !canAccessPremiumGames(authInfo)) {
      closeMenu();
      navigate('/manage-plan');
    } else {
      closeMenu();
      setActiveGameId(DEFAULT_GAME_ID);
      setGameStarted(true);
    }
  }, [closeMenu, setGameStarted, clientId, games, canAccessPremiumGames, authInfo, navigate, setActiveGameId]);

  const retryLoadGames = useCallback(() => {
    gtag('event', 'retry_load_game_click', {
      'unique_client_id': clientId
    });
    loadGames();
  }, [loadGames, clientId]);

  useEffect(() => {
    document.title = 'Trolley Problem Game';
  }, []);

  if (loadingStatus === RequestStatus.IN_PROGRESS) {
    return (
      <div className="page-loading-spinner">
        <i className="fa-solid fa-spinner rotating-spinner"></i>
      </div>
    );
  }

  if (loadingStatus === RequestStatus.ERROR) {
    return (
      <div className="fetch-page-error">
        Oops, something went wrong. Please try again.
        <button
          className="app-button retry-btn"
          onClick={retryLoadGames}
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <>
      {!gameStarted && <StartScreen
        key="start-screen"
        onStartClick={startGame}
        className={'active'}
      />}
      <Game
        gameId={DEFAULT_GAME_ID}
        closeMenu={closeMenu}
        isHidden={!gameStarted}
        setActiveGameId={setActiveGameId}
      />
    </>);
}

Home.propTypes = {
  closeMenu: PropTypes.func,
  setActiveGameId: PropTypes.func
};

export default Home;
